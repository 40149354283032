.card {
    margin: 20px;
    background-color: rgba(white, 0.35);
    width: fit-content;
    max-width: 200px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

    &_inner {
        display: flex;
        flex-direction: column;
        padding: 10px;

        &_image {

            background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
            background-size: 400% 400% !important;
            animation: gradient 8s ease infinite;
            border-radius: 10px;
            width: 180px;
            height: 180px;
            

            &--image {
                width: 180px;
                //display: none;

            }
        }

        &--info {
            display: flex;
            justify-content: space-between;
            //padding: 5px 0;
            position: relative;
            top: -20px;
            cursor: default;

            &-tab {
                display: flex;
                background: white;
                border-radius: 5px;
                padding: 5px;

                svg {
                    width: 18px;
                    margin-right: 2px;
                }
                p {
                    margin: 0;
                }
            }
        }
    }
}

.card_inner-desc {
    display: flex;
    justify-content: space-around;
    p {
        text-transform: uppercase;
    }

    &--elems {
        display: flex;
    }
}

.card_inner-tags {
    overflow-wrap: break-all;

    display: flex;
    justify-content: space-around;

    font-weight: 600;
    color: #222;
    height: 80px;
}

.dna-elem {
    position: relative;
    transition: all 0.3s ease;
    cursor: default;
    background: rgba(white, 0.5);
    width: 100%;
    text-align: center;
    margin: 2.5px;
    padding: 1px;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center;

    &-inner {
        //padding: 2px 8px;
        transition: inherit;
    }

    .title {
        font-size: 20px;
        font-weight: 900;
        color: #222;
        margin: 0;
        transition: 0.8s ease 600ms;
        font: "Poppins", sans-serif;
        text-align: center;
    }

    .description {
        font-size: 12px;
        margin-top: -0.2em;
    }

    &:hover {
        transform: scale(1.05);
        z-index: 10;

        .title,
        .description {
            -webkit-text-fill-color: #222;
        }
    }
}

.card_inner-rarity {
    display: flex;
    justify-content: space-around;
}

// For lite card ------------
.genome_buttons {
    display: flex;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}
