@font-face {
  font-family: "Gameplay";
  src: local("Gameplay"), url("../assets/fonts/Gameplay.ttf") format("truetype");
  font-weight: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Oxygen&display=swap");

@import "card";
@import "colors";
@import "story";


* {
  box-sizing: border-box;
  font-family: "Oxygen", sans-serif;
}

body {
  margin: 0;
  background-image: url("../assets/images/hero-image.png");
  //background-image: url("../assets/images/stars.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: $col5;
  background-attachment: fixed;


  // hide scrollbar
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;

}
// hidde scrollbar on Chrome, safari, opera
body::-webkit-scrollbar {
  display: none;
}

.App,
#root {
  height: 100vh;
}

// Document --------------
.navbar {
  padding: 15px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba($col2, 0.5);
  backdrop-filter: blur(2px);
  z-index: 999;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-image: linear-gradient(45deg, #ffd800, #20e4ff, #ff00c8);
  border-image-source: linear-gradient(45deg, rgb(255, 216, 0), rgb(32, 228, 255), rgb(255, 0, 200));
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-image-slice: 1;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  &--left {
    h1, .h1 {
      text-decoration: none;
      margin: 0;
      letter-spacing: 2px;
      color: $col3;
      font-family: "Gameplay";
      cursor: pointer;
      user-select: none;
      transition: all 0.2s ease;

      &:hover {
        text-shadow: 0 0 10px $col3;
      }
    }
  }

  &--right {
    display: flex;
    align-items: center;

    h3, .h3 {
      text-decoration: none;
      letter-spacing: 1.5px;
      padding-right: 1em;
      color: $col3;
      margin: 0;
      cursor: pointer;
      user-select: none;

      transition: all 0.2s ease;

      &:hover {
        text-shadow: 0 0 10px $col3;
      }
    }
  }
}


.hero {
  height: 100%;
  background-image: url("../assets/images/hero-image.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;

  &__middle {
    text-align: center;
    // Phage Image
    &--phage {
      width: 500px;
      animation: float 6s ease-in-out infinite;
      cursor: pointer;
    }

    h1 {
      font-size: 300%;
      color: $col3;
      text-align: center;
      text-shadow: 0 0 45px rgba($col3, 0.5);
    }

    span {
      background: -webkit-linear-gradient(92deg, #95d7e3, #eb76ff);
      background-size: 600vw 600vw;

      // make sure to put these after the background property
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      animation: textAnimate 5s linear infinite alternate;
    }

    .h3 {
      text-decoration: none;
    }

  }

  &__lower {
    display: flex;
    justify-content: space-evenly;

    &--btn1 {
      padding: 20px;
      font-size: 2em;
      font-weight: 800;
      letter-spacing: 2px;
      border-radius: 5px;
      background-color: $col3;
      color: $col2 !important;

      text-align: center;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s ease;
    }

    &--btn2 {
      padding: 20px;
      font-size: 2em;
      font-weight: 800;
      letter-spacing: 2px;
      border-radius: 5px;
      background-color: $col3;
      color: $col2 !important;

      text-align: center;
      cursor: pointer;
      user-select: none;
      transition: all 0.2s ease;

    }
  }
}

.connect-btn {
  padding: 5px !important;
  width: fit-content;
  border-radius: 12px;
  background-color: $col3;
  color: $col2 !important;

  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: $col5;
    color: $col3 !important;
  }
}

.collection {
  margin-top: 100px;
  

  &-sort {
    width: fit-content;
    margin: auto;
    padding: 5px;
    border: 2px solid white;
    border-radius: 12px;
    backdrop-filter: blur(2px);
    background: rgba($col2, 0.5);
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;

    div{
      background: #fefdfd;
      color: $col2;
      padding: 4px;
      margin: 6px;
      border-radius: 4px;
      cursor: pointer;
      user-select: none;
      display: flex;

      transition: all 0.2s ease;

      svg {
        transition: all 0.6s ease;
      }
    }
  }

  &-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

}


// Effect classes ----------------
.margin-large {
  @media (min-width: 1500px) {
    margin-left: 10%;
    margin-right: 10%;
  }
}



// Animations ---------------
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

@keyframes textAnimate {
  from {
    filter: hue-rotate(0deg);
    background-position-x: 0%;
  }
  to {
    filter: hue-rotate(360deg);
    background-position-x: 600vw;
  }
}
