@import "colors";

.story {
    margin-top: 100px;
    margin-left: 2em;
    margin-right: 2em;

    h1 {
        color: $col3;
    }
    p {
        color: $col3;
    }

    &-phages {


        &-pics {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

        }
    }
}